import gql from 'graphql-tag'

export const workLogsSave = gql`
    mutation (
            $worklogs: [WorkLogInput]
        ) {
        workLogs(
            worklogs: $worklogs
        ) {
            id
        }
    }`;

export const workLogsApprove = gql`
    mutation ($worklogs: [String]) {
        workLogsApprove(
            worklogs: $worklogs
        )
    }`;

export const workLogs = gql`
    query($date: String!, $employee_id: String, $employee_ids: [String]) {
        workLogs(date: $date, employee_id: $employee_id, employee_ids: $employee_ids) {
            id
            employee_id
            construction_zone_id
            state
            date
            work_minutes
            work_place
            activity_kz
            work_start
            work_end
            pause_start
            pause_end
            drive_status
            drive_time
            compensation_zone
            compensation_payment_type
            compensation_amount
            missing_reason
            missing_time
            holidays_enabled
            holiday
            holiday_time
            illness
            illness_time
            time_account
            time_account_text
            missing_reason_additional
            missing_additional_time
            work_on_sunday
            work_on_sunday_time
            work_on_night
            work_on_night_time
            privat_car
            meal_allowance
            meal_allowance_taxable
            bonus
            costs
            arrival_key
            accommodation
            release_key
            foreman_approved_at
            foreman_approved_id
            manager_approved_at
            manager_approved_id
            admin_approved_at
            admin_approved_id
            foremanApproved {
                id
                firstname
                lastname
            }
            managerApproved {
                id
                firstname
                lastname
            }
            adminApproved {
                id
                firstname
                lastname
            }
            changed
            readonly
        }
    }`

export const workLog = gql`
    query($id: String!) {
        workLog(id: $id) {
            id
            construction_zone_id
            state
            date
            work_minutes
            work_place
            activity_kz
            work_start
            work_end
            pause_start
            pause_end
            drive_status
            drive_time
            compensation_zone
            compensation_payment_type
            compensation_amount
            missing_reason
            missing_time
            holidays_enabled
            holiday
            holiday_time
            illness
            illness_time
            time_account
            time_account_text
            missing_reason_additional
            missing_additional_time
            work_on_sunday
            work_on_sunday_time
            work_on_night
            work_on_night_time
            privat_car
            meal_allowance
            meal_allowance_taxable
            costs
            bonus
            arrival_key
            accommodation
            release_key
            foreman_approved_at
            foreman_approved_id
            manager_approved_at
            manager_approved_id
            admin_approved_at
            admin_approved_id
        }
    }`

export const checkout = gql`
    query($date: String!, $construction_zone_id: String, $department_id: String, $search: String) {
        checkout(date: $date, construction_zone_id: $construction_zone_id, department_id: $department_id, search: $search)
    }`