import gql from 'graphql-tag'

export const costructionZonesPaginated = gql`
    query($search: String, $page: Int, $limit: Int) {
        constructionZones(search: $search, page: $page, limit: $limit) {
            data {
                id
                name
                construction_zone_number
                company {
                    id
                    name
                }
                managers {
                    id
                    firstname
                    lastname
                }
                foremans {
                    id
                    firstname
                    lastname
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }`

export const constructionZonesList = gql`
    query($company_id: String, $use: Boolean) {
        constructionZonesList(company_id: $company_id, use: $use) {
            id
            company_id
            name
            construction_zone_number
            state
            managers {
                id
                firstname
                lastname
            }
            foremans {
                id
                firstname
                lastname
            }
        }
    }`

export const updateConstructionZone = gql`
    mutation (
            $id: String,
            $name: String!,
            $construction_zone_number: String,
            $state: String,
            $company_id: String!,
            $foreman_ids: [String]
            $manager_ids: [String]
        ) {
        constructionZone(
            id: $id,
            name: $name,
            state: $state,
            construction_zone_number: $construction_zone_number,
            company_id: $company_id,
            foreman_ids: $foreman_ids
            manager_ids: $manager_ids
        ) {
            id
            name
            construction_zone_number
            state
            company_id
            foreman_ids
            manager_ids
            company {
                id
                name
            }
            managers {
                id
                firstname
                lastname
            }
            foremans {
                id
                firstname
                lastname
            }
        }
    }`;

export const removeConstructionZone = gql`
    mutation ($id: String!) {
        removeConstructionZone(id: $id) {
            id
        }
    }`;

export const fetchConstructionZone = gql`
    query ($id: Int!) {
        constructionZone(id:$id) {
            id
            name
            construction_zone_number
            state
            company_id
            foreman_ids
            manager_ids
            company {
                id
                name
            }
            managers {
                id
                firstname
                lastname
            }
            foremans {
                id
                firstname
                lastname
            }
        }
    }`;