import gql from 'graphql-tag'

export const fetchMe = gql`
    query{
        me {
            id
            firstname
            lastname
            company_id
            department_id
            personal_number
            construction_zone_name
            position
            activity_kz
            start_date
            end_date
            night_hour_regulation
            activity_admission
            active
            user {
                email
                role
                renew_password
            }
            company {
                id
                name
                work_on_night_start
                work_on_night_end
            }
        }
    }`;

export const constants = gql`
    query{
        constants
    }`;
export const exportQuery = gql`
    query($date: String!){
        export(date: $date)
    }`;
export const loginQuery = gql`
    query ($email: String!,$password: String!) {
        login(email:$email,password:$password)
    }`

export const changePassword = gql`
    mutation ($password: String!, $new_password: String!) {
        changePassword(password: $password, new_password: $new_password)
    }`;