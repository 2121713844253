<template>
    <v-dialog
            v-model="dialog"
            max-width="800"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                Option
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-overlay :value="loading"></v-overlay>
                <validation-observer
                        ref="observer"
                >
                    <v-row v-if="!loading">
                        <v-col cols="12" sm="12">
                            <zw-input name="value"
                                      rules="required"
                                      label="Value"
                                      v-model="form.value"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-input name="description"
                                      label="Description"
                                      rules="required"
                                      v-model="form.description"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-autocomplete name="roles"
                                             label="Roles"
                                             v-model="form.roles"
                                             multiple
                                             :items="roles"
                                             :readonly="readonly"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-autocomplete name="departments"
                                             label="Betriebsstääte (all if empty)"
                                             v-model="form.departments"
                                             multiple
                                             :items="departments"
                                             :readonly="readonly"
                            ></zw-autocomplete>
                        </v-col>
                    </v-row>
                </validation-observer>

                <div v-if="!readonly" class="d-flex d-sm-none">
                    <v-bottom-navigation fixed background-color="success">
                        <v-btn @click="save()" icon class="white--text link">
                            <v-icon>check</v-icon>
                        </v-btn>
                    </v-bottom-navigation>
                </div>

            </v-card-text>
            <v-card-actions class="pr-6" v-if="!readonly && $vuetify.breakpoint.smAndUp">
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        class="white--text"
                        @click="save()"
                >
                    <span v-if="form.id">Speichern</span>
                    <span v-else>HINZUFÜGEN</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {updateOption, fetchOption} from './../graphql/option'
    import {fetchRoles} from './../graphql/role'
    import gql from 'graphql-tag'
    import {departmentsList} from './../graphql/department'

    export default {
        data: () => ({
            dialog: false,
            loading: true,
            readonly: false,
            callback: null,
            form: {},
            defaultForm: {
                'value': '',
                'description': '',
                'roles': [],
            }
        }),
        apollo: {
            'roles': {
                query: fetchRoles,
                fetchPolicy: 'network-only',
                update: function (data) {
                    return data.roles.map(role => {
                        return {
                            text: role.name,
                            value: role.code,
                        }
                    })
                }
            },
            'departments': {
                query: departmentsList,
                fetchPolicy: 'network-only',
                update: function (data) {
                    return data.departmentsList.map(department => {
                        return {
                            text: department.name,
                            value: department.id,
                        }
                    })
                }
            },
        },
        methods: {
            ...mapGetters(['getOption']),
            close() {
                this.dialog = false
                this.loading = true
            },
            open(id, readonly = false, callback = null, group) {
                this.readonly = readonly
                this.form = {...this.defaultForm}
                this.callback = callback
                this.dialog = true

                if (id) {
                    this.$apollo.query({
                        query: fetchOption,
                        variables: {
                            id: parseInt(id),
                        },
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {
                        this.form = {...data.option}
                        this.loading = false
                    })
                } else {
                    this.form.group = group
                    this.loading = false
                }

            },
            save() {
                this.$refs.observer.validate().then(valid => {
                    if (valid) {
                        this.$apollo.mutate({
                            mutation: updateOption,
                            variables: {
                                id: this.form.id,
                                group: this.form.group,
                                value: this.form.value,
                                description: this.form.description,
                                roles: this.form.roles,
                                departments: this.form.departments,
                            },
                        }).then((data) => {
                            if (this.callback) {
                                this.callback()
                            }
                            this.close()
                        })
                    }
                })
            },
        }
    }
</script>