<template>
    <v-dialog
            ref="dialog"
            v-model="modal"
            width="290px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                    v-model="inputVal"
                    :label="label"
                    readonly
                    dense outlined hide-details
                    v-bind="attrs"
                    v-on="readonly ? null : on"
                    :error-messages="errorMessages"
            ></v-text-field>
        </template>
        <v-time-picker
                v-if="modal"
                v-model="inputVal"
                full-width
                format="24hr"
                header-color="success"
                @change="changed"
                @input="changed"
                color="success"
                :max="max"
                :min="min"
                :allowed-minutes="allowedMinutes"
        >
            <v-btn
                    text
                    color="accent"
                    @click="set24"
            >
                set 24:00
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                    text
                    color="primary"
                    @click="unset"
            >
                Unset
            </v-btn>
            <v-btn
                    color="primary"
                    @click="$refs.dialog.save(inputVal)"
            >
                OK
            </v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<script>
    export default {
        props: ['value', 'name', 'rules', 'label', 'readonly', 'max', 'min', 'errorMessages', 'allowedMinutes'],
        data: () => ({
            modal: false,
            internalVal: null
        }),
        methods: {
            changed() {
                this.$emit('change')
                this.modal = false
            },
            set24() {
                this.internalVal = '24:00'
                this.$emit('input', this.internalVal);
                this.changed()
                this.modal = false
            },
            unset() {
                this.internalVal = null
                this.$emit('input', this.internalVal);
                this.changed()
                this.modal = false
            }
        },
        mounted: function () {
            this.internalVal = this.value
        },
        computed: {
            inputVal: {
                get() {
                    return this.internalVal;
                },
                set(val) {
                    this.internalVal = val
                    this.$emit('input', val);
                }
            }
        },
        watch: {
            value: function (value) {
                this.internalVal = value
            }
        }
    }
</script>