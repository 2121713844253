import gql from 'graphql-tag'

export const updateOption = gql`
    mutation ($id: String, $group: String!, $value: String!, $description: String, $roles: [String], $departments: [String]) {
        option(id: $id, group: $group, value: $value, description: $description, roles: $roles, departments: $departments) {
            id
            group
            value
            description
            roles
            departments
        }
    }`;

export const fetchOption = gql`
    query ($id: Int!) {
        option(id:$id) {
            id
            group
            value
            description
            roles
            departments
        }
    }`;

export const removeOption = gql`
    mutation ($id: String!) {
        removeOption(id: $id) {
            id
        }
    }`;

export const fetchOptions = gql`
    {
        options {
            id
            group
            value
            description
            roles
            departments
        }
    }`;