<template>
    <v-dialog
            v-model="dialog"
            max-width="800"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                <template v-if="form.id">Mitarbeiter bearbeiten</template>
                <template v-else>Mitarbeiter hinzufügen</template>
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-overlay :value="loading"></v-overlay>
                <validation-observer
                        ref="observer"
                >
                    <v-row v-if="!loading">
                        <v-col cols="12" sm="6">
                            <zw-input name="email"
                                      rules="required"
                                      label="Benutzername"
                                      v-model="form.user.email"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-switch v-if="!newPassword && !readonly" name="renew"
                                       label="vorübergehendes Passwort erstellen"
                                       v-model="newPassword"
                            ></zw-switch>
                            <zw-input v-if="newPassword" name="password"
                                      :rules="form.id ? '' : 'required'"
                                      label="Passwort"
                                      type="password"
                                      v-model="form.user.password"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-select name="role"
                                       rules="required"
                                       label="Role"
                                       v-model="form.user.role"
                                       :items="roles"
                                       :readonly="readonly"
                            ></zw-select>
                        </v-col>
                        <v-col cols="12" sm="12" v-if="form.user.role == 'BD'">
                          <zw-autocomplete name="role_options"
                                           label="Role Betriebsstätten"
                                           v-model="form.role_options"
                                           item-text="name"
                                           item-value="id"
                                           :items="departments"
                                           :readonly="readonly"
                                           :multiple="true"
                          ></zw-autocomplete>
                        </v-col>

                        <v-col v-if="form.user.role != 'PR'" cols="12" sm="6">
                            <zw-autocomplete name="company_id"
                                             :rules="form.user.role == 'PR' ? '' : 'required'"
                                             label="Firma"
                                             v-model="form.company_id"
                                             item-text="name"
                                             item-value="id"
                                             :items="companiesList"
                                             :readonly="readonly"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col v-if="form.user.role != 'PR'" cols="12" sm="6">
                            <zw-autocomplete name="department_id"
                                             :rules="form.user.role == 'PR' ? '' : 'required'"
                                             label="Betriebsstätten"
                                             v-model="form.department_id"
                                             item-text="name"
                                             item-value="id"
                                             :items="departments"
                                             :readonly="readonly"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-input name="personal_number"
                                      label="Personalnummer"
                                      v-model="form.personal_number"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-input name="construction_zone_name"
                                      label="Stammkostenstelle"
                                      v-model="form.construction_zone_name"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" xs="12">
                            <zw-input name="settlement_office"
                                      label="Abrechnungsstelle"
                                      v-model="form.settlement_office"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="6" xs="6">
                            <zw-input name="lastname"
                                      label="Name"
                                      v-model="form.lastname"
                                      rules="required"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="6" xs="6">
                            <zw-input name="firstname"
                                      label="Vorname"
                                      v-model="form.firstname"
                                      rules="required"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-select name="position"
                                       v-model="form.position"
                                       label="Tätigkeitsschlüssel"
                                       :items="$root.$children[0].getConstant('positions')"
                                       :readonly="readonly"
                                       @change="positionChanged"
                            ></zw-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-radio-group name="activity_kz"
                                            v-model="form.activity_kz"
                                            label="Tätigkeitskennzeichen"
                                            :items="$root.$children[0].getOptionsWithValue('tkz', form.activity_kz, true)"
                                            item-text="label"
                                            item-value="id"
                                            :readonly="readonly"
                            ></zw-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-date name="start_date"
                                     label="Eintrittsdatum"
                                     v-model="form.start_date"
                                     :readonly="readonly"
                            ></zw-date>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-date name="end_date"
                                     label="Austrittsdatum"
                                     v-model="form.end_date"
                                     :readonly="readonly"
                                     clearable
                            ></zw-date>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-switch name="night_hour_regulation"
                                       label="Nachtstundenregel"
                                       v-model="form.night_hour_regulation"
                                       :readonly="readonly"
                            ></zw-switch>
                            <zw-switch name="activity_admission"
                                       label="Tätigkeitszulassung"
                                       v-model="form.activity_admission"
                                       :readonly="readonly || ['trainee', 'employed'].includes(form.position)"
                            ></zw-switch>
                            <zw-switch name="active"
                                       label="Aktiv"
                                       v-model="form.active"
                                       :readonly="readonly"
                            ></zw-switch>
                        </v-col>
                    </v-row>
                </validation-observer>

                <div v-if="!readonly" class="d-flex d-sm-none">
                    <v-bottom-navigation fixed background-color="success">
                        <v-btn @click="save()" icon class="white--text link">
                            <v-icon>check</v-icon>
                        </v-btn>
                    </v-bottom-navigation>
                </div>
            </v-card-text>
            <v-card-actions class="pr-6" v-if="!readonly && $vuetify.breakpoint.smAndUp">
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        class="white--text"
                        @click="save()"
                >
                    <span v-if="form.id">Speichern</span>
                    <span v-else>HINZUFÜGEN</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {fetchEmployee, updateEmployee} from './../graphql/employee'
    import {fetchRoles} from './../graphql/role'
    import {companiesList} from './../graphql/company'
    import {departmentsList} from './../graphql/department'

    export default {
        data: () => ({
            dialog: false,
            loading: true,
            readonly: false,
            newPassword: false,
            form: {},
            callback: null,
            departments: [],
            defaultForm: {
                company_name: '',
                company_id: '',
                personal_number: '',
                construction_zone_name: '',
                email: '',
                firstname: '',
                lastname: '',
                position: '',
                activity_kz: '1',
                night_hour_regulation: false,
                activity_admission: false,
                start_date: null,
                end_date: null,
                user: {
                    email: '',
                    password: '',
                    role: 'MA',
                }
            }
        }),
        apollo: {
            'roles': {
                query: fetchRoles,
                fetchPolicy: 'network-only',
                update: function (data) {
                    return data.roles.map(role => {
                        return {
                            text: role.name,
                            value: role.code,
                        }
                    })
                }
            },
            companiesList: companiesList
        },
        methods: {
            close() {
                this.dialog = false
                this.loading = true
                this.newPassword = false
            },
            open(id, readonly = false, callback = null) {
                this.readonly = readonly
                this.callback = callback
                this.form = JSON.parse(JSON.stringify(this.defaultForm))
                this.dialog = true

                if (id) {
                    this.$apollo.query({
                        query: fetchEmployee,
                        variables: {
                            id: parseInt(id),
                        },
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {
                        this.form = {...data.employee}
                        this.loading = false
                    })
                } else {
                    this.newPassword = true
                    this.loading = false
                }
            },
            save() {
                this.$refs.observer.validate().then(valid => {
                    if (valid) {
                        this.$apollo.mutate({
                            mutation: updateEmployee,
                            variables: {
                                ...this.form,
                                user: {
                                    email: this.form.user.email,
                                    role: this.form.user.role,
                                    password: this.form.user.password,
                                }
                            },
                        }).then((data) => {
                            if (this.callback) {
                                this.callback()
                            }
                            this.close()
                        }).catch((error) => {
                            let {graphQLErrors} = error;
                            if (graphQLErrors[0].extensions.validation) {
                                this.$refs.observer.setErrors(graphQLErrors[0].extensions.validation);
                            }
                        })
                    }
                })
            },
            positionChanged() {
                if (['trainee', 'employed'].includes(this.form.position)) {
                    this.form.activity_admission = false
                }
            }
        },
        watch: {
            'form.company_id': function (companyId) {
                const company = this.companiesList.filter(company => company.id == companyId)[0]
                if (company) {
                    this.$apollo.query({
                        query: departmentsList,
                        variables: {
                            company_id: company.id,
                        },
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {
                        this.departments = data.departmentsList.map(department => {
                            return department
                        })
                    })
                } else {
                    this.departments = []
                }
            }
        },
    }
</script>