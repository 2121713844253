<template>
  <validation-provider
      v-slot="{ errors }"
      :name="name"
      :rules="rules"
  >
    <div>{{ label }}</div>
    <v-radio-group
        v-model="inputVal"
        row
        dense
    >
      <v-radio v-for="item in items"
               color="success"
               :label="item.text"
               :value="item.value"
               :key="item.value"
               :readonly="readonly"
               :disabled="disabled"
      ></v-radio>
    </v-radio-group>
  </validation-provider>
</template>

<script>
export default {
  props: ['value', 'name', 'rules', 'label', 'items', 'readonly', 'disabled'],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
}
</script>