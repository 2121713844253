<template>
    <div id="app">
        <v-app v-if="!loading" :style="{...cssProps,...cssVars}">
            <!--      mobile header-->
            <div class="d-flex d-sm-none">
                <v-app-bar app color="success" class="white--text">
                    <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                    <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn @click="logout()" v-if="me.id" icon>
                        <v-icon color="white">
                            mdi-logout
                        </v-icon>
                    </v-btn>
                    <v-btn v-else icon>
                        <v-icon color="white">
                            mdi-login
                        </v-icon>
                    </v-btn>
                </v-app-bar>
            </div>

            <!--      desktop header-->
            <div class="d-none d-sm-flex">
                <v-app-bar color="white" flat app>
                    <v-container class="py-0 fill-height ">
                        <a href="/" class="mr-5">
                            <img style="height: 50px" src="@/assets/bug-verkehrsbau-se.png"/>
                        </a>
                        {{ label }}
                        <v-btn v-for="(menu,index) in topMenuItems" :key="index" :to="{ path: menu.to }" exact-path
                               color="success"
                               text
                               class="link text-capitalize">
                            <v-icon left>
                                {{ menu.icon }}
                            </v-icon>
                            {{ menu.title }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn v-if="me.id" @click="logout" color="success" text class="text-capitalize">
                            <v-icon left>
                                mdi-logout
                            </v-icon>
                            Abmelden
                        </v-btn>
                        <v-btn v-else color="success" text class="text-capitalize">
                            <v-icon left>
                                mdi-login
                            </v-icon>
                            Anmelden
                        </v-btn>

                        <v-app-bar app color="success" style="top:64px;" height="40">
                            <v-container class="py-0 fill-height">
                                <v-btn v-for="(menu,index) in menuItems" :key="index" :to="menu.to" x-small
                                       color="white" text class="text-capitalize link">
                                    <v-icon left>
                                        {{ menu.icon }}
                                    </v-icon>
                                    {{ menu.title }}
                                </v-btn>
                            </v-container>
                        </v-app-bar>

                    </v-container>
                </v-app-bar>
            </div>

            <!--      mobile sidebar-->
            <v-navigation-drawer
                    v-model="drawer"
                    fixed
                    temporary
                    style="z-index: 300;position: fixed"
            >
                <v-app-bar color="success" class="white--text">
                    <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                </v-app-bar>

                <v-list
                        nav
                        dense
                >
                    <v-list-item-group
                            active-class="success--text"
                    >
                        <v-list-item v-for="(menu, index) in topMenuItems" :key="index" :to="menu.to" class="link">
                            <v-list-item-icon>
                                <v-icon>{{ menu.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ menu.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item v-for="(menu, index) in menuItems" :key="'2_'+index" :to="menu.to" class="link">
                            <v-list-item-icon>
                                <v-icon>{{ menu.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ menu.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                        <v-list-item v-if="me.id" @click="logout()" class="link">
                            <v-list-item-icon>
                                <v-icon>mdi-logout</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Abmelden</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else class="link">
                            <v-list-item-icon>
                                <v-icon>mdi-login</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Anmelden</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>

                <template v-slot:append>
                    <v-row>
                        <v-col>
                            <a style="display: block;text-align: center" href="/">
                                <img style="width: 70%" src="@/assets/bug-verkehrsbau-se.png"/>
                            </a>
                        </v-col>
                    </v-row>
                </template>
            </v-navigation-drawer>

            <!--      mobile bottom menu-->
            <div v-if="bottomMenu.length" class="d-flex d-sm-none">
                <v-bottom-navigation fixed background-color="success">
                    <v-btn :disabled="menu.disabled" v-for="(menu, index) in bottomMenu" @click="menu.event"
                           :key="index" icon class="white--text link">
                        <v-icon>{{ menu.icon }}</v-icon>
                        <span v-if="menu.title">{{ menu.title }}</span>
                    </v-btn>
                </v-bottom-navigation>
            </div>

            <!--      content-->
            <v-main :class="$vuetify.breakpoint.smAndUp ? 'padding' : 'mobile-padding'">
                <v-container :fluid="$route.meta.fullScreen">
                    <router-view/>
                </v-container>
            </v-main>

            <!--      modals-->
            <template v-if="me.id">
                <confirm ref="confirm"></confirm>
                <company-form ref="company-form"></company-form>
                <employee-form ref="employee-form"></employee-form>
                <construction-zone-form ref="construction-zone-form"></construction-zone-form>
                <department-form ref="department-form"></department-form>
                <role-form ref="role-form"></role-form>
                <option-form ref="option-form"></option-form>
                <worklog-form ref="worklog-form"></worklog-form>
            </template>

            <FlashMessage time="20000" style="z-index: 5"/>

            <zw-time-modal ref="time-modal"></zw-time-modal>
        </v-app>
    </div>
</template>

<script>
    import {onLogout} from './vue-apollo'
    import {fetchMe, constants} from './graphql/user'
    import {fetchOptions} from './graphql/option'

    export default {
        name: 'App',
        data: () => ({
            loading: true,
            label: process.env.NODE_ENV === 'development' ? "DEVELOPMENT" : "",
            me: {
                user: {},
            },
            drawer: false,
            bottomMenu: [],
            trigger: 0
        }),
        apollo: {
            'constants': {
                query: constants,
                update: function (data) {
                    return JSON.parse(data.constants)
                },
                skip() {
                    return !this.me.id
                },
                fetchPolicy: 'network-only',
            },
            options: {
                query: fetchOptions,
                skip() {
                    return !this.me.id
                },
                fetchPolicy: 'network-only'
            },
        },
        methods: {
            getConstant(name) {
                let options = []
                Object.keys(this.constants[name]).forEach((key) => {
                    let text = this.constants[name][key]
                    options.push({
                        'text': text,
                        'value': key,
                    })
                })

                return options
            },
            getOptionsWithValue(group, value, description = false) {
                const myRole = this.me.user.role
                let options = this.options ? this.options.filter(option => {
                    return option.group == group && (option.roles.includes('ALL') || option.roles.includes(myRole))
                }) : []

                options = options.filter(option => {
                    if (option.departments) {
                        return option.departments.includes(this.me.department_id)
                    }
                    return true
                })

                let newOptions = options.map(option => {
                    return {
                        id: option.value,
                        value: option.value,
                        label: description ? option.description : option.value,
                        text: description ? option.description : option.value,
                    }
                })

                if (value) {
                    const foundOption = newOptions.find(option => option.id == value)
                    if (!foundOption) {
                        newOptions.push({
                            id: value,
                            label: value,
                        })
                    }
                }

                return newOptions
            },
            logout() {
                this.me = {
                    user: {}
                }
                onLogout(this.$apolloProvider.defaultClient)
                this.$router.push({name: 'Login'})
            },
            loadData() {
                if (localStorage.getItem('apollo-token')) {
                    this.$apollo.query({
                        query: fetchMe,
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {
                        this.me = {...data.me}
                        if (this.me.user.renew_password) {
                            if (this.$router.history.current.name != 'ResetPassword') {
                                this.$router.push({name: 'ResetPassword'})
                            }
                        }
                        this.loading = false

                        if (this.$route.meta.permissions) {
                            if (!this.$route.meta.permissions.includes(this.me.user.role)) {
                                if (this.me.user.role == 'PR') {
                                    this.$router.push({name: 'Checkoff'})
                                } else {
                                    this.$router.push({name: 'TimeManagement'})
                                }


                            }
                        }

                    }).catch(() => {
                        localStorage.removeItem('apollo-token')
                        this.$router.push({name: 'Login'})
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    if (this.$router.history.current.name != 'Login') {
                        this.$router.push({name: 'Login'})
                    }
                    this.loading = false
                }
            },
            successMessage() {
                this.flashMessage.show({
                    status: 'success',
                    message: 'Erfolgreich gespeichert!'
                });
            },
            errorMessage(message = null) {
                this.flashMessage.show({
                    status: 'error',
                    message: message ? message : 'Fehler!'
                });
            },
            validationMessage() {
                this.flashMessage.show({
                    status: 'warning',
                    message: 'Bitte füllen Sie alle notwendige Felder aus!'
                });
            },
            updateCss() {
                this.trigger += 1
            }
        },
        mounted: function () {
            this.me = {
                user: {}
            }
            this.loadData()

            window.setInterval(() => {
                this.updateCss()
            }, 1000)
        },
        watch: {
            $route: function () {
                this.loading = true
                this.drawer = false
                this.bottomMenu = []
                this.loadData()
            }
        },
        computed: {
            topMenuItems: function () {
                if (!this.me.id) {
                    return []
                }
                let menu = []
                let itemTimeManagement = {
                    to: '/',
                    title: 'Zeitverwaltung',
                    icon: 'mdi-timer',
                }
                let itemTimeManagementList = {
                    to: '/list',
                    title: 'Kolonnenerfassung',
                    icon: 'mdi-timer',
                }
                let itemUser = {
                    to: '/user',
                    title: 'Benutzer',
                    icon: 'mdi-account',
                }

                if (['A', 'MA', 'PO', 'BA', 'BB', 'BD', 'VE'].includes(this.me.user.role)) {
                    menu.push(itemTimeManagement)
                    if (['A', 'PO', 'BA', 'BB', 'BD', 'VE'].includes(this.me.user.role)) {
                        menu.push(itemTimeManagementList)
                    }
                    menu.push(itemUser)
                } else if (['PR'].includes(this.me.user.role)) {
                    menu.push(itemUser)
                }

                return menu;
            },
            menuItems: function () {
                if (!this.me.id) {
                    return []
                }
                let menu = []

                let itemCheckoff = {
                    to: '/checkoff',
                    title: 'BlCheckoff',
                    icon: 'mdi-checkbox-marked-circle-outline',
                }
                let itemCompanies = {
                    to: '/companies',
                    title: 'Firmen',
                    icon: 'mdi-domain',
                }
                let itemCZ = {
                    to: '/construction-zones',
                    title: 'Baustellen',
                    icon: 'mdi-crane',
                }
                let itemDepartments = {
                    to: '/departments',
                    title: 'Betriebsstätten',
                    icon: 'mdi-file-tree',
                }
                let itemEmployees = {
                    to: '/employees',
                    title: 'Mitarbeiter',
                    icon: 'mdi-worker',
                }
                let itemSettings = {
                    to: '/settings',
                    title: 'Einstellungen',
                    icon: 'mdi-settings',
                }

                if (['A', 'PO', 'BA', 'BB','BD', 'VE', 'PR'].includes(this.me.user.role)) {
                    menu.push(itemCheckoff)
                }
                if (['BB'].includes(this.me.user.role)) {
                    menu.push(itemCZ)
                }
                if (['A', 'VE'].includes(this.me.user.role)) {
                    menu.push(itemCompanies)
                    menu.push(itemDepartments)
                    menu.push(itemCZ)
                    menu.push(itemEmployees)
                    menu.push(itemSettings)
                }

                return menu
            },
            cssProps() {
                let themeColors = {}
                Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
                    themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
                })
                return themeColors
            },
            cssVars() {
                let height = 100
                if (this.trigger && document.getElementById("top-header")) {
                    height = document.getElementById("top-header").getBoundingClientRect().bottom
                }

                return {
                    '--header-top': height + 'px'
                }
            }
        }

    }
</script>

<style lang="scss">
    html {
        overflow: unset !important;
    }

    body {
        display: grid;
        overflow-x: auto;
    }

    .v-bottom-navigation {
        .v-btn__content {
            color: white !important;
        }

        button[disabled] {
            .v-btn__content {
                color: rgba(0, 0, 0, .26) !important
            }
        }
    }

    .link {
        &.v-btn:before {
            background-color: inherit !important;
        }

        .v-btn__content {
            color: #DBE7B7;
        }

        &.v-btn--active .v-btn__content {
            color: inherit;
        }

        &.v-list-item--link:before {
            background-color: inherit !important;
        }
    }

    .v-dialog--fullscreen {
        margin-top: 56px !important;
        padding-bottom: 56px;
        height: calc(100% - 56px) !important;
    }

    .v-input--selection-controls {
        margin-top: 0px !important;
    }

    .v-data-table-header, .v-data-table-header th {
        background-color: #94C12D;
    }

    .v-data-table.v-data-table--fixed-header thead th {
        background-color: #94C12D !important;
    }

    .v-main.padding {
        padding-top: 104px !important;
    }

    .v-main.mobile-padding {
        padding-bottom: 70px !important;
    }

    table th + th {
        border-left: 1px solid #dddddd !important;
    }

    table td + td {
        border-left: 1px solid #dddddd !important;
    }

    .weekend {
        background-color: var(--v-secondary);
    }

    .error-row {
        background-color: var(--v-accent_light);
    }


    .table-item {
        padding: 2px;
        min-height: 36px;

        &.readonly {
            background-color: #FFEBEE;
        }

        &.approved {
            background-color: var(--v-success_light);
        }

        &.changed {
            input, .v-select__selection {
                color: var(--v-accent) !important;
            }
        }

        &.text {
            line-height: 32px;
        }
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        height: 38px !important;
    }

    .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
        margin-top: 4px !important;
    }

    .v-input {
        font-size: 12px;
    }

    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 12px !important;
        padding: 0 2px !important;
    }

    .v-btn:not(.v-btn--round).v-size--small {
        min-width: 40px;
        padding: 0;
    }

    .custom-fixed-table {
        .v-data-table {
            th {
                position: sticky;
                top: 104px;
                z-index: 1;
            }
        }

        .v-data-table {
            .v-data-table__wrapper {
                overflow: unset;
            }
        }
    }

    .modal-buttons {
        position: fixed;
        bottom: 0;
        height: 56px;
        background-color: white !important;
        border-color: white !important;

        bottom: 0;
        display: flex;
        left: 0;
        justify-content: center;
        width: 100%;
    }

    ._vue-flash-msg-body._vue-flash-msg-body_success ._vue-flash-msg-body__content {
        border-color: var(--v-success) !important;
    }

    ._vue-flash-msg-body._vue-flash-msg-body_error ._vue-flash-msg-body__content {
        border-color: var(--v-accent) !important;
    }

    ._vue-flash-msg-body._vue-flash-msg-body_warning ._vue-flash-msg-body__content {
        border-color: var(--v-accent) !important;
    }

    ._vue-flash-msg-body._vue-flash-msg-body_success {
        border: solid 1px var(--v-success_light) !important;
        background-color: var(--v-success_light) !important;
        color: black;
    }

    ._vue-flash-msg-body._vue-flash-msg-body_error, ._vue-flash-msg-body._vue-flash-msg-body_warning {
        border: solid 1px var(--v-accent_light) !important;
        background-color: var(--v-accent_light) !important;
        color: black;
    }

    .v-toolbar__title {
        white-space: normal !important;
    }

    .v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
        padding: 0 6px !important;
    }

    @media all and (min-width: 0px) and (max-width: 599px) {
        .v-input {
            font-size: 16px;
        }
    }
</style>
