<template>
    <v-dialog
            v-model="dialog"
            max-width="800"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                Betriebsstätten
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-overlay :value="loading"></v-overlay>
                <validation-observer
                        ref="observer"
                >
                    <v-row v-if="!loading">
                        <v-col cols="12" sm="12">
                            <zw-input name="name"
                                      rules="required"
                                      label="Name"
                                      v-model="form.name"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-input name="number"
                                      rules="required"
                                      label="Nummer"
                                      v-model="form.number"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-autocomplete name="company_id"
                                             rules="required"
                                             label="Firma"
                                             v-model="form.company_id"
                                             item-text="name"
                                             item-value="id"
                                             :items="companiesList"
                                             :readonly="readonly"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-input name="description"
                                      label="Bezeichnung"
                                      v-model="form.description"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                    </v-row>
                </validation-observer>

                <div v-if="!readonly" class="d-flex d-sm-none">
                    <v-bottom-navigation fixed background-color="success">
                        <v-btn @click="save()" icon class="white--text link">
                            <v-icon>check</v-icon>
                        </v-btn>
                    </v-bottom-navigation>
                </div>
            </v-card-text>
            <v-card-actions class="pr-6" v-if="!readonly && $vuetify.breakpoint.smAndUp">
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        class="white--text"
                        @click="save()"
                >
                    <span v-if="form.id">Speichern</span>
                    <span v-else>HINZUFÜGEN</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {fetchDepartment, updateDepartment} from './../graphql/department'
    import {companiesList} from './../graphql/company'

    export default {
        data: () => ({
            dialog: false,
            loading: true,
            readonly: false,
            form: {},
            callback: null,
            defaultForm: {
                'name': '',
                'company_id': null,
                'number': null,
                'description': null,
            },
        }),
        apollo: {
            companiesList: {
                query: companiesList,
                fetchPolicy: 'network-only'
            },
        },
        methods: {
            close() {
                this.dialog = false
                this.loading = true
            },
            open(id, readonly = false, callback = null) {
                this.readonly = readonly
                this.callback = callback
                this.form = JSON.parse(JSON.stringify(this.defaultForm))
                this.dialog = true

                if (id) {
                    this.$apollo.query({
                        query: fetchDepartment,
                        variables: {
                            id: parseInt(id),
                        },
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {
                        this.form = {...data.department}
                        this.loading = false
                    })
                } else {
                    this.loading = false
                }
            },
            save() {
                this.$refs.observer.validate().then(valid => {
                    if (valid) {
                        this.$apollo.mutate({
                            mutation: updateDepartment,
                            variables: {...this.form},
                        }).then((data) => {
                            if (this.callback) {
                                this.callback()
                            }
                            this.close()
                        })
                    }
                })
            },
        },
    }
</script>