<template>
    <validation-provider
            v-slot="{ errors }"
            :name="name"
            :rules="rules"
    >
        <v-text-field v-model="inputVal"
                      :label="label"
                      dense
                      outlined
                      hide-details
                      :readonly="readonly"
                      :error-messages="errors"
                      @change="update"
                      :max="max"
                      :min="min"
                      type="number"
                      :style="disableCustomWidth ? '':'width:80px'"
        ></v-text-field>
    </validation-provider>
</template>

<script>
    export default {
        props: ['name', 'value', 'label', 'readonly', 'rules', 'min', 'max','disableCustomWidth'],
        computed: {
            inputVal: {
                get() {
                    return this.value ? (this.value / 60).toFixed(2) : this.value
                },
                set(val) {
                    // this.$emit('input', val * 60)
                }
            }
        },
        methods: {
            update(newValue) {
                let time = parseFloat(newValue.replace(',', '.'))
                this.$emit('input', (time ? time : 0) * 60)
            }
        }
    }
</script>