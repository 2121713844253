import gql from 'graphql-tag'

export const employeesPaginated = gql`
    query($search: String, $page: Int, $limit: Int) {
        employees(search: $search, page: $page, limit: $limit) {
            data {
                id
                firstname
                lastname
                active
                company {
                    id
                    name
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }`

export const employeesList = gql`
    query($company_id: String,  $construction_zone_id: String, $department_id: String, $date: String) {
        employeesList(company_id: $company_id, , construction_zone_id: $construction_zone_id, department_id: $department_id, date: $date) {
            id
            firstname
            lastname
            personal_number
            activity_kz
            activity_admission
            active
            construction_zone_name
            department_id
            end_date
            position
            user {
                email
                role
            }
            start_date
            company_id
            night_hour_regulation
            company {
                id
                name
                work_on_night_start
                work_on_night_end
            }
        }
    }`

export const updateEmployee = gql`
    mutation (
            $id: String,
            $firstname: String,
            $lastname: String,
            $company_id: String,
            $department_id: String,
            $personal_number: String,
            $construction_zone_name: String,
            $position: String,
            $activity_kz: String,
            $start_date: String,
            $end_date: String,
            $settlement_office: String,
            $night_hour_regulation: Boolean,
            $activity_admission: Boolean,
            $active: Boolean,
            $user: UserInput,
            $role_options: [String]
        ) {
        employee(
            id: $id,
            firstname: $firstname,
            lastname: $lastname,
            company_id: $company_id,
            department_id: $department_id,
            personal_number: $personal_number,
            construction_zone_name: $construction_zone_name,
            position: $position,
            activity_kz: $activity_kz,
            start_date: $start_date,
            end_date: $end_date,
            night_hour_regulation: $night_hour_regulation,
            activity_admission: $activity_admission,
            active: $active,
            user: $user,
            role_options: $role_options,
            settlement_office: $settlement_office,
        ) {
            id
            firstname
            lastname
            company_id
            department_id
            personal_number
            construction_zone_name
            position
            activity_kz
            start_date
            end_date
            night_hour_regulation
            activity_admission
            active
            role_options
            settlement_office
        }
    }`;

export const removeEmployee = gql`
    mutation ($id: String!) {
        removeEmployee(id: $id) {
            id
        }
    }`;

export const fetchEmployee = gql`
    query ($id: Int!) {
        employee(id:$id) {
            id
            firstname
            lastname
            company_id
            department_id
            personal_number
            construction_zone_name
            position
            activity_kz
            start_date
            end_date
            night_hour_regulation
            activity_admission
            active
            settlement_office
            role_options
            user {
                email
                role
            }
            company {
                id
                name
                work_on_night_start
                work_on_night_end
            }
        }
    }`;

export const pdfReport = gql`
    query($employee_id: Int!,$date: String!){
        report(employee_id:$employee_id,date:$date)
    }`;