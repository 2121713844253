<template>
    <v-dialog
            v-model="dialog"
            max-width="800"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                Worklog
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-overlay :value="loading"></v-overlay>
                <validation-observer
                        ref="observer"
                >
                    <v-row v-if="!loading">
                        <v-col cols="12" sm="4">
                            <zw-date name="date"
                                     rules="required"
                                     label="Datum"
                                     v-model="form.date"
                                     :readonly="readonly"
                                     :max="today()"
                                     hide-details
                            ></zw-date>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-autocomplete name="construction_zone_id"
                                             label="Baustelle"
                                             v-model="form.construction_zone_id"
                                             :items="constructionZonesList"
                                             item-text="label"
                                             item-value="id"
                                             :readonly="readonly"
                                             hide-details
                                             :rules="{required: !form.missing_reason}"
                                             :custom-option-label="true"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-autocomplete name="state"
                                             label="Bundesland"
                                             v-model="form.state"
                                             :items="$root.$children[0].getOptionsWithValue('state', form.state)"
                                             item-text="label"
                                             item-value="id"
                                             :readonly="readonly"
                                             hide-details
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="form.work_place"
                                          label="Einsatzort"
                                          dense
                                          outlined
                                          hide-details
                                          :readonly="readonly"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <zw-time v-model="form.work_start"
                                     :max="form.work_end"
                                     @change="calculateWorkingTime(form)"
                                     label="Anwesenheit von"
                                     :readonly="readonly"
                                     :allowed-minutes="allowedStep"
                            ></zw-time>
                        </v-col>
                        <v-col cols="6">
                            <zw-time v-model="form.work_end"
                                     :min="form.work_start"
                                     @change="calculateWorkingTime(form)"
                                     label="Anwesenheit bis"
                                     :readonly="readonly"
                                     :allowed-minutes="allowedStep"
                            ></zw-time>
                        </v-col>

                        <v-col cols="6">
                            <zw-time v-model="form.pause_start"
                                     :max="form.pause_end"
                                     @change="calculateWorkingTime(form)"
                                     label="Pause von"
                                     :readonly="readonly"
                                     :allowed-minutes="allowedStep"
                            ></zw-time>
                        </v-col>
                        <v-col cols="6">
                            <zw-time v-model="form.pause_end"
                                     :min="form.pause_start"
                                     @change="calculateWorkingTime(form)"
                                     label="Pause bis"
                                     :readonly="readonly"
                                     :allowed-minutes="allowedStep"
                            ></zw-time>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="form.compensation_zone"
                                      label="Wegstreckenentschädigung"
                                      :items="$root.$children[0].getOptionsWithValue('zone', form.compensation_zone, true)"
                                      dense outlined hide-details
                                      :readonly="readonly"
                                      item-text="label"
                                      item-value="id"
                                      clearable
                                      single-line
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <div v-if="['VE', 'A'].includes(getMe().user.role)">
                                <zw-input v-model="form.compensation_payment_type"
                                          label="Wegstreckenentschädigung"
                                          dense outlined hide-details
                                          :readonly="readonly"
                                          type="number"
                                          min="0"
                                          :rules="{min_value:0}"
                                ></zw-input>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div v-if="['VE', 'A'].includes(getMe().user.role)">
                                <zw-input v-model="form.compensation_amount"
                                          label="Wegstreckenentschädigung"
                                          dense outlined hide-details
                                          :readonly="readonly"
                                          type="number"
                                          min="0"
                                          :rules="{min_value:0}"
                                ></zw-input>
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="isSunday(form.date)">
                            <zw-time-field label="Sonntags-zuschlag"
                                           v-model="form.work_on_sunday_time"
                                           :readonly="readonly"
                                           :disableCustomWidth="true"
                            ></zw-time-field>
                        </v-col>

                        <v-col cols="12">
                            <zw-switch v-model="inputs.missing"
                                       label="Abwesenheit"
                                       :readonly="readonly"
                            ></zw-switch>
                        </v-col>
                        <template v-if="inputs.missing">
                            <v-col cols="8">
                                <zw-select name="missing_reason"
                                           label="Abwesenheit"
                                           v-model="form.missing_reason"
                                           @change="missingReasonChanged()"
                                           :items="$root.$children[0].getOptionsWithValue('missing', form.missing_reason)"
                                           item-text="label"
                                           item-value="id"
                                           :readonly="readonly"
                                ></zw-select>
                            </v-col>
                            <v-col cols="4">
                                <zw-time-field v-model="form.missing_time"
                                               label="h"
                                               :readonly="readonly"
                                               :disableCustomWidth="true"
                                ></zw-time-field>
                            </v-col>
                        </template>


                        <v-col cols="12">
                            <zw-switch v-model="form.holidays_enabled"
                                       label="Feiertag"
                                       :readonly="readonly"
                            ></zw-switch>
                        </v-col>
                        <template v-if="form.holidays_enabled">
                            <v-col cols="8">
                                <v-select v-model="form.holiday"
                                          label="Feiertag"
                                          v-if="form.holidays_enabled"
                                          :items="$root.$children[0].getOptionsWithValue('holiday', form.holiday)"
                                          item-text="label"
                                          item-value="id"
                                          dense outlined hide-details
                                          :readonly="readonly"
                                          clearable
                                          single-line
                                ></v-select>
                            </v-col>
                            <v-col cols="4">
                                <zw-time-field v-model="form.holiday_time"
                                               v-if="form.holidays_enabled"
                                               :readonly="readonly"
                                               label="h"
                                               rules="min_value:0"
                                               :min="0"
                                               :disableCustomWidth="true"
                                ></zw-time-field>
                            </v-col>
                        </template>

                        <v-col cols="12">
                            <zw-switch v-model="form.privat_car"
                                       label="Anreise Privat"
                                       :readonly="readonly"
                            ></zw-switch>
                        </v-col>
                        <template v-if="form.privat_car && ['VE', 'A'].includes(getMe().user.role)">
                            <v-col cols="8">
                                <zw-input v-model="form.arrival_key"
                                          label="Anreiseschlüssel"
                                          v-if="form.privat_car"
                                          dense outlined hide-details
                                          :readonly="readonly"
                                          type="number"
                                          min="0"
                                          rules="{min_value:0,max:4}"
                                ></zw-input>
                            </v-col>
                            <v-col cols="4">
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="costs"
                                        rules="min_value:0"
                                >
                                    <v-text-field v-model="form.costs"
                                                  label="Kosten"
                                                  v-if="form.privat_car"
                                                  type="number"
                                                  min="0"
                                                  :error-messages="errors"
                                                  dense outlined hide-details
                                                  :readonly="readonly"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </template>

                        <v-col cols="12">
                            <zw-switch v-model="form.accommodation"
                                       label="Unterkunft privat"
                                       :readonly="readonly"
                            ></zw-switch>
                        </v-col>
                        <template v-if="form.accommodation && ['VE', 'A'].includes(getMe().user.role)">
                            <v-col cols="8">
                                <zw-input v-model="form.release_key"
                                          v-if="form.accommodation"
                                          label="Auslöseschlüssel"
                                          dense outlined hide-details
                                          :readonly="readonly"
                                          type="number"
                                          min="0"
                                          :rules="{min_value:0,max:4}"
                                ></zw-input>
                            </v-col>
                            <v-col cols="4">
                                <zw-time-field v-model="form.bonus"
                                               label="Zulage"
                                               v-if="form.accommodation"
                                               :readonly="readonly"
                                               rules="min_value:0"
                                               :min="0"
                                               :disableCustomWidth="true"
                                ></zw-time-field>
                            </v-col>
                        </template>

                        <v-col cols="12">
                            <zw-switch v-model="form.meal_allowance"
                                       label="Verpflegungspauschale"
                                       :readonly="readonly"
                            ></zw-switch>
                        </v-col>

                        <v-col v-if="1 == 0" cols="12">
                            <zw-switch v-model="form.meal_allowance_taxable"
                                       label="VPStpfl"
                                       :readonly="readonly"
                            ></zw-switch>
                        </v-col>

                        <v-col cols="12" sm="4">
                            <zw-select name="activity_kz"
                                       label="Tätigkeitskennnzeichen"
                                       v-model="form.activity_kz"
                                       :items="$root.$children[0].getOptionsWithValue('tkz', form.activity_kz)"
                                       item-text="label"
                                       item-value="id"
                                       :readonly="readonly"
                                       hide-details
                            ></zw-select>
                        </v-col>

                    </v-row>
                </validation-observer>

                <div class="d-flex d-sm-none">
                    <div class="modal-buttons pt-2" background-color="success">
                        <template v-if="!readonly">
                            <v-btn class="mr-2" color="primary" @click="save(false)">
                                Speichern
                            </v-btn>
                            <v-btn color="secondary" @click="save(true)">
                                Speichern + Kopieren
                            </v-btn>
                        </template>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="pr-6" v-if="!readonly && $vuetify.breakpoint.smAndUp">
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        class="white--text"
                        @click="save()"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import worklogsCalculations from '@/mixins/worklogsCalculations'
    import moment from "moment"
    import {workLog, workLogsSave} from './../graphql/worklog'
    import {fetchEmployee} from './../graphql/employee'
    import {constructionZonesList} from './../graphql/constructionZone'

    export default {
        mixins: [worklogsCalculations],
        data: () => ({
            dialog: false,
            loading: true,
            readonly: false,
            inputs: {
                missing: false,
            },
            form: {},
            employeeData: {},
            constructionZonesList: [],
            callback: null
        }),
        methods: {
            getMe() {
                return this.$root.$children[0].me;
            },
            allowedStep: m => m % 15 === 0,
            missingReasonChanged() {
                if (this.form.missing_reason) {
                    this.form.work_start = null
                    this.form.work_end = null
                    this.form.pause_start = null
                    this.form.pause_end = null
                }
            },
            close() {
                this.dialog = false
                this.loading = true
            },
            open(id, employee_id, date = null, readonly = false, callback) {
                this.callback = callback
                this.readonly = readonly

                // this.$store.dispatch('resetWorklog').then(() => {
                this.dialog = true
                if (id) {
                    this.$apollo.query({
                        query: workLog,
                        variables: {
                            id: id,
                        },
                        fetchPolicy: 'network-only'
                    }).then((result) => {
                        this.form = result.data.workLog

                        this.$apollo.query({
                            query: fetchEmployee,
                            variables: {
                                id: parseInt(employee_id),
                            },
                            fetchPolicy: 'network-only'
                        }).then(({data}) => {
                            this.employeeData = {...data.employee}
                            this.$apollo.query({
                                query: constructionZonesList,
                                variables: {
                                    company_id: this.employeeData.company_id,
                                },
                                fetchPolicy: 'network-only'
                            }).then(({data}) => {
                                this.constructionZonesList = data.constructionZonesList.map(zone => {
                                    zone.label = zone.construction_zone_number
                                    zone.custom_label = zone.construction_zone_number + ' ' + zone.name
                                    return zone
                                })
                            }).finally(() => {
                                this.loading = false
                            })
                        })
                    })

                } else {
                    this.form = {
                        date: date,
                        state: null,
                    }
                    this.$apollo.query({
                        query: fetchEmployee,
                        variables: {
                            id: parseInt(employee_id),
                        },
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {
                        this.employeeData = {...data.employee}
                        this.form.activity_kz = this.employeeData.activity_kz ?? 1
                        this.$apollo.query({
                            query: constructionZonesList,
                            variables: {
                                company_id: this.employeeData.company_id,
                            },
                            fetchPolicy: 'network-only'
                        }).then(({data}) => {
                            this.constructionZonesList = data.constructionZonesList.map(zone => {
                                zone.label = zone.construction_zone_number
                                zone.custom_label = zone.construction_zone_number + ' ' + zone.name
                                return zone
                            })
                        }).finally(() => {
                            this.loading = false
                        })
                    })
                }
            },
            save(copy = false) {
                this.$refs.observer.validate().then(valid => {
                    if (valid) {

                        let item = {...this.form}

                        // date must be set
                        if (!item.date) return null

                        delete item.__typename
                        delete item.managerApproved
                        delete item.foremanApproved
                        delete item.adminApproved
                        delete item.changed
                        delete item.readonly

                        // employee_id is not given by form
                        // and will not be set by variables for workLogsSave
                        item.employee_id = this.employeeData.id

                        this.$apollo.mutate({
                            mutation: workLogsSave,
                            variables: {
                                worklogs: [item],
                                // this will not set employee_id for workLogsSave
                                // TODO: fix or remove?
                                employee_id: this.employeeData.id
                            },
                        }).then(() => {
                            if (copy) {
                                let nextDate = moment(this.form.date).add(1, 'day').format('YYYY-MM-DD')

                                this.form.id = null
                                this.form.date = nextDate
                                this.form.changed = []
                                this.form.manager_approved_id = null
                                this.form.manager_approved_at = null
                                this.form.foreman_approved_id = null
                                this.form.foreman_approved_at = null
                            } else {
                                if (this.callback) {
                                    this.callback()
                                }
                                this.close()
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
            today() {
                return moment().format('YYYY-MM-DD')
            }
        },
        watch: {
            'inputs.missing': function (enabled) {
                if (!enabled) {
                    this.form.missing_reason = null
                    this.form.missing_time = null
                }
            },
        }
    }
</script>