import gql from 'graphql-tag'

export const departmentsPaginated = gql`
    query($search: String, $page: Int, $limit: Int) {
        departments(search: $search, page: $page, limit: $limit) {
            data {
                id
                name
                number
                description
                company {
                    id
                    name
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }`

export const departmentsList = gql`
    query($company_id: String) {
        departmentsList(company_id: $company_id) {
            id
            name
            number
            description
        }
    }`

export const updateDepartment = gql`
    mutation (
            $id: String,
            $name: String!,
            $company_id: String!
            $number: String!
            $description: String
        ) {
        department(
            id: $id,
            name: $name,
            company_id: $company_id
            number: $number
            description: $description
        ) {
            id
            name
            company_id
            number
            description
            company {
                id
                name
            }
        }
    }`;

export const removeDepartment = gql`
    mutation ($id: String!) {
        removeDepartment(id: $id) {
            id
        }
    }`;

export const fetchDepartment = gql`
    query ($id: Int!) {
        department(id:$id) {
            id
            name
            company_id
            number
            description
            company {
                id
                name
            }
        }
    }`;