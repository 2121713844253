<template>
    <validation-provider
            v-slot="{ errors }"
            :name="name"
            :rules="rules"
    >
        <v-text-field
                v-model="inputVal"
                outlined
                dense
                :label="label"
                :error-messages="errors"
                :type="type"
                :min="min"
                :max="max"
                :readonly="readonly"
                :disabled="disabled"
                :hide-details="hideDetails"
        ></v-text-field>
    </validation-provider>
</template>

<script>
    export default {
        props: ['value', 'name', 'rules', 'label', 'type', 'readonly', 'disabled', 'hideDetails', 'min', 'max'],
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
    }
</script>