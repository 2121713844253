import gql from 'graphql-tag'

export const updateRole = gql`
    mutation ($id: String!, $name: String!) {
        role(id: $id, name: $name) {
            id
            name
        }
    }`;

export const fetchRole = gql`
    query ($id: Int!) {
        role(id:$id) {
            id
            name
            code
        }
    }`;

export const fetchRoles = gql`
    {
        roles {
            id
            name
            code
        }
    }`;