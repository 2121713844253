<template>
    <v-dialog
            v-model="showLogin"
            persistent
            max-width="800"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                Login
            </v-card-title>
            <v-card-text>
                <v-form>
                    <validation-observer
                            ref="observer"
                            v-slot="{ invalid }"
                    >

                        <form novalidate class="md-layout md-alignment-center-center">
                            <validation-provider
                                    v-slot="{ errors }"
                                    name="email"
                                    rules="required"
                            >
                                <v-text-field
                                        v-model="form.email"
                                        outlined
                                        label="Benutzername"
                                        :error-messages="errors"
                                        required
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider
                                    v-slot="{ errors }"
                                    name="password"
                                    rules="required"
                            >
                                <v-text-field
                                        v-model="form.password"
                                        outlined
                                        type="password"
                                        label="Passwort"
                                        :error-messages="errors"
                                        required
                                        @keydown.enter="login"
                                ></v-text-field>
                            </validation-provider>

                            <v-btn
                                    @click="login"
                                    color="secondary"
                                    class="mr-4"
                                    elevation="2"
                            >Log in
                            </v-btn>

                            <loading :active="isLoading" :is-full-page="false"/>
                        </form>
                    </validation-observer>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {onLogin} from '../vue-apollo'
    import ValidateMixin from '@/mixins/validate'
    import {loginQuery, fetchMe} from './../graphql/user'

    export default {
        mixins: [ValidateMixin],
        data() {
            return {
                isLoading: false,
                showLogin: true,
                form: {
                    email: "",
                    password: ""
                }
            };
        },
        methods: {
            login() {
                this.$refs['observer'].validate().then(valid => {
                    if (valid) {
                        this.isLoading = true;

                        this.$apollo.query({
                            client: 'unsecured',
                            query: loginQuery,
                            variables: {
                                email: this.form.email,
                                password: this.form.password,
                            },
                            fetchPolicy: 'network-only'
                        }).then(({data}) => {
                            onLogin(this.$apolloProvider.clients.secured, data.login)
                            this.$router.push({name: 'TimeManagement'})
                        }).catch((data) => {
                            this.$refs['observer'].setErrors({
                                'email': 'Wrong credentials',
                            })
                        }).finally(() => {
                            this.isLoading = false;
                        })
                    }
                })
            }
        }
    };
</script>