<template>
    <validation-provider
            v-slot="{ errors }"
            :name="name"
            :rules="rules"
    >
        <v-autocomplete
                v-model="inputVal"
                outlined
                dense
                :label="label"
                :error-messages="errors"
                :items="items"
                :item-text="itemText"
                :item-value="itemValue"
                :readonly="readonly"
                :clearable="clearable"
                :hide-details="hideDetails"
                :multiple="multiple"
        >
            <template v-if="customOptionLabel" v-slot:item="{ item }">
                <div class="v-list-item__content">
                    <div class="v-list-item__title">{{item.custom_label}}</div>
                </div>
            </template>
        </v-autocomplete>
    </validation-provider>
</template>

<script>
    export default {
        props: ['value', 'name', 'rules', 'label', 'items', 'itemText', 'itemValue', 'readonly', 'clearable', 'hideDetails', 'multiple', 'customOptionLabel'],
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
    }
</script>