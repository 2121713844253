<template>
  <validation-provider
      v-slot="{ errors }"
      :name="name"
      :rules="rules"
  >
    <v-switch
        v-model="inputVal"
        :label="label"
        color="success"
        hide-details
        class="text-uppercase"
        :readonly="readonly"
    ></v-switch>
  </validation-provider>
</template>

<script>
export default {
  props: ['value', 'name', 'rules', 'label', 'readonly'],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
}
</script>