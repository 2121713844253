import gql from 'graphql-tag'

export const companiesList = gql`
    query {
        companiesList {
            id
            name
            company_number
            foremans {
                id
                firstname
                lastname
            }
            managers {
                id
                firstname
                lastname
            }
        }
    }`

export const companiesPaginated = gql`
    query($search: String, $page: Int, $limit: Int) {
        companies(search: $search, page: $page, limit: $limit) {
            data {
                id
                name
                company_number
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }`

export const updateCompany = gql`
    mutation ($id: String, $name: String!, $company_number: String, $work_on_night_start: String!, $work_on_night_end: String!) {
        company(
            id: $id,
            name: $name,
            company_number: $company_number,
            work_on_night_start: $work_on_night_start,
            work_on_night_end: $work_on_night_end
        ) {
            id
            name
        }
    }`;

export const removeCompany = gql`
    mutation ($id: String!) {
        removeCompany(id: $id) {
            id
        }
    }`;

export const fetchCompany = gql`
    query ($id: Int!) {
        company(id:$id) {
            id
            name
            company_number
            work_on_night_start
            work_on_night_end
        }
    }`;