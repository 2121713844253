<template>
  <validation-provider
      v-slot="{ errors }"
      :name="name"
      :rules="rules"
  >
    <v-select
        v-model="inputVal"
        :label="label"
        outlined
        dense
        :items="items"
        :error-messages="errors"
        :item-text="itemText"
        :item-value="itemValue"
        :readonly="readonly"
        :hide-details="hideDetails"
        :multiple="multiple"
        :disabled="disabled"
        @change="$emit('change', inputVal);"
    ></v-select>
  </validation-provider>
</template>

<script>
export default {
  props: ['value', 'name', 'rules', 'label', 'items', 'readonly', 'itemText', 'itemValue', 'hideDetails', 'multiple', 'disabled'],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
}
</script>