import moment from "moment";

export default {
    methods: {
        itemClasses(item, field = null) {
            let classes = [];
            if (this.isReadOnly(item)) {
                classes.push("readonly");
            }
            if (this.isApproved(item)) {
                classes.push("approved");
            }

            let mergedFields = {
                work: ["work_start", "work_end"],
                pause: ["pause_start", "pause_end"],
                missing: ["missing_reason", "missing_time"],
            };
            if (item.changed) {
                if (Object.keys(mergedFields).includes(field)) {
                    mergedFields[field].forEach((mField) => {
                        if (item.changed.includes(mField)) {
                            classes.push("changed");
                        }
                    });
                } else {
                    if (item.changed.includes(field)) {
                        classes.push("changed");
                    }
                }
            }

            return classes;
        },
        isReadOnly(item) {
            if (item.readonly) {
                return true;
            }

            if (this.getMe().user.role == "MA") {
                return item.manager_approved_id || item.foreman_approved_id || item.admin_approved_id ? true : false;
            }
            if (this.getMe().user.role == "PO") {
                return item.manager_approved_id || item.foreman_approved_id || item.admin_approved_id ? true : false;
            }
            if (this.getMe().user.role == "BA" || this.getMe().user.role == "BB" || this.getMe().user.role == "BD") {
                return item.manager_approved_id || item.admin_approved_id ? true : false;
            }

            return false;
        },
        isApproved(item) {
            if (this.getMe().user.role == "MA") {
                return item.manager_approved_id || item.foreman_approved_id || item.admin_approved_id ? true : false;
            }
            if (this.getMe().user.role == "PO") {
                return item.manager_approved_id || item.foreman_approved_id || item.admin_approved_id ? true : false;
            }
            if (this.getMe().user.role == "BA" || this.getMe().user.role == "BB" || this.getMe().user.role == "BD") {
                return item.manager_approved_id || item.admin_approved_id ? true : false;
            }
            if (this.getMe().user.role == "A" || this.getMe().user.role == "VE") {
                return item.admin_approved_id ? true : false;
            }

            return false;
        },
        calculateWorkingTime(subItem) {
            this.calculateSubItemTime(subItem);
            // if (subItem.missing_reason) {
            //     if (subItem.missing_time) {
            //         subItem.work_minutes = subItem.missing_time
            //     } else {
            //         subItem.work_minutes = 8 * 60
            //     }
            // } else if (subItem.illness) {
            //     if (subItem.illness_time) {
            //         subItem.work_minutes = subItem.illness_time
            //     } else {
            //         subItem.work_minutes = null
            //     }
            // } else {
            //     this.calculateSubItemTime(subItem)
            // }
        },
        calculateSubItemTime(item) {
            // https://projects.thethinklink.de/my-work?modal=Task-25028-874
            let employeeData = typeof this.employeeData !== "undefined" ? this.employeeData : this.getEmployeeById(item.employee_id);
            let company = employeeData.company;

            let workingTime = 0;
            let pauseTime = 0;

            if (item.work_end && item.work_start) {
                let work_start = this.toMinutes(item.work_start);
                let work_end = this.toMinutes(item.work_end);

                let pause_start = 0;
                let pause_end = 0;
                if (item.pause_start && item.pause_end) {
                    pause_start = this.toMinutes(item.pause_start);
                    pause_end = this.toMinutes(item.pause_end);
                }

                workingTime = work_end - work_start;

                // https://projects.thethinklink.de/my-work?modal=Task-25028-874
                if (employeeData.night_hour_regulation) {
                    let nightPause = this.calculateNightTime(company, pause_start, pause_end);
                    let nightWork = this.calculateNightTime(company, work_start, work_end);

                    item.work_on_night_time = nightWork - nightPause;
                }
            } else {
                workingTime = 0;
            }

            if (item.pause_start && item.pause_end) {
                pauseTime = this.calculatePauseTime(this.toMinutes(item.work_start), this.toMinutes(item.work_end), this.toMinutes(item.pause_start), this.toMinutes(item.pause_end));
            } else {
                pauseTime = 0;
            }

            if (workingTime) {
                item.work_minutes = workingTime - pauseTime;
                if (this.isSunday(item.date)) {
                    item.work_on_sunday_time = item.work_minutes;
                }
            } else {
                item.work_minutes = null;
                item.work_on_night_time = null;
                item.work_on_sunday_time = null;
            }
        },
        calculatePauseTime(work_start, work_end, pause_start, pause_end) {
            let pauseTime = 0;

            if (pause_start >= work_start && pause_start <= work_end) {
                // started in work frame
                if (pause_end <= work_end) {
                    // ended in work frame
                    pauseTime = pause_end - pause_start;
                } else {
                    // pause ends after work
                    pauseTime = work_end - pause_start;
                }
            } else if (pause_start < work_start) {
                // started before work
                if (pause_end > work_start && pause_end <= work_end) {
                    // ends in work time
                    pauseTime = pause_end - work_start;
                } else if (pause_end > work_start && pause_end > work_end) {
                    // ends after work
                    pauseTime = work_end - work_start;
                }
            }

            return pauseTime;
        },
        calculateNightTime(company, work_start, work_end) {
            let night_start = this.toMinutes(company.work_on_night_start);
            let night_end = this.toMinutes(company.work_on_night_end);
            let nightWorkTime = 0;

            if (work_start >= night_start) {
                // work [21:00-23:00] started after [20:00]
                nightWorkTime += work_end - work_start;
            } else if (work_end > night_start) {
                //work [15:00-23:00] ended after [20:00]
                nightWorkTime += work_end - night_start;
            }

            if (work_start <= night_end && work_end >= night_end) {
                // work [02:00-13:00] started before [05:00]
                nightWorkTime += night_end - work_start;
            } else if (work_start <= night_end && work_end < night_end) {
                // work [02:00-04:00] started before [05:00]
                nightWorkTime += work_end - work_start;
            }

            return nightWorkTime;
        },
        isWeekend(date) {
            return moment(date).isoWeekday() == 6 || moment(date).isoWeekday() == 7;
        },
        isSunday(date) {
            return moment(date).isoWeekday() == 7;
        },
        toMinutes(str) {
            if (!str) {
                return 0;
            }
            let pieces = str.split(":");

            return parseInt(pieces[0]) * 60 + parseInt(pieces[1]);
        },
        keyValueToItems(data) {
            let result = [];
            Object.keys(data).forEach((key) => {
                result.push({
                    text: data[key],
                    value: key,
                });
            });

            return result;
        },
    },
};
