import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                primary: '#495258',
                secondary: '#999999',
                accent: '#FF906D',
                success: '#94C12D',
                success_light: '#DBE7B7',
                accent_light: '#ffc7b6',
            },
        },
    },
});