<template>
    <div>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="roles"
                        :server-items-length="roles.length"
                        :loading="loading"
                        class="elevation-1"
                        hide-default-footer
                >
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="openRoleForm(item.id, true)" small icon>
                            <v-icon>
                                mdi-eye
                            </v-icon>
                        </v-btn>
                        <v-btn @click="openRoleForm(item.id)" small icon color="success">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import gql from 'graphql-tag'
    import {fetchRoles} from './../graphql/role'

    export default {
        data() {
            return {
                loading: false,
                roles: [],
                headers: [
                    {
                        text: 'Id',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'Name',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: 'Code',
                        sortable: false,
                        value: 'code',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                        width: '120px'
                    }
                ],
            };
        },
        apollo: {
            roles: {
                query: fetchRoles,
                fetchPolicy: 'network-only'
            },
        },
        methods: {
            openRoleForm(id, readonly = false) {
                this.$root.$children[0].$refs['role-form'].open(id, readonly)
            },
        }
    }
</script>