<template>
    <v-dialog
            ref="dialog"
            v-model="modal"
            width="290px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-time-picker
                v-if="modal"
                v-model="value"
                full-width
                format="24hr"
                header-color="success"
                @change="changed"
                @input="changed"
                color="success"
                :max="max"
                :min="min"
                :allowed-minutes="allowedMinutes"
        >
            <v-btn
                    text
                    color="accent"
                    @click="set24"
            >
                set 24:00
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                    text
                    color="primary"
                    @click="unset"
            >
                Unset
            </v-btn>
            <v-btn
                    color="primary"
            >
                OK
            </v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<script>
    export default {
        props: [],
        data: () => ({
            modal: false,
            value: null,
            max: null,
            min: null,
            allowedMinutes: null,
            callback: () => {
            },
        }),
        methods: {
            open(value, min, max, allowedMinutes, callback) {
                this.value = value
                this.min = min
                this.max = max
                this.allowedMinutes = allowedMinutes
                this.callback = callback
                this.modal = true
            },
            changed() {
                this.callback(this.value)
                this.modal = false
            },
            set24() {
                this.value = '24:00'
                this.changed()
                this.modal = false
            },
            unset() {
                this.value = null
                this.changed()
                this.modal = false
            },
        },
    }
</script>