export default {
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        getValidationClass({dirty, invalid}) {
            return {
                'md-invalid': dirty && invalid
            }
        },
    }
};