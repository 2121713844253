import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        props: route => ({query: route.query.employee_id}),
        name: 'TimeManagement',
        component: () => import('../views/TimeManagement.vue'),
        meta: {
            title: 'Zeitverwaltung',
            fullScreen: true,
            permissions: ['A', 'MA', 'PO', 'BA', 'BB', 'BD', 'VE']
        }
    },
    {
        path: '/list',
        name: 'Kolonenverwaltung',
        component: () => import('../views/TimeManagement.vue'),
        meta: {
            title: 'Kolonenverwaltung',
            fullScreen: true,
            permissions: ['A', 'PO', 'BA', 'BB', 'BD', 'VE'],
            listMode: true
        }
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('../views/User.vue'),
        meta: {
            title: 'Benutzer',
            permissions: ['A', 'MA', 'PO', 'BA', 'BB', 'BD', 'PR', 'VE']
        }
    },
    {
        path: '/employees',
        name: 'Employees',
        component: () => import('../views/Employees.vue'),
        meta: {
            title: 'Mitarbeiter',
            permissions: ['A', 'VE']
        }
    },
    {
        path: '/companies',
        name: 'Companies',
        component: () => import('../views/Companies.vue'),
        meta: {
            title: 'Firmen',
            permissions: ['A', 'VE']
        }
    },
    {
        path: '/construction-zones',
        name: 'ConstructionZones',
        component: () => import('../views/ConstructionZones.vue'),
        meta: {
            title: 'Baustellen',
            permissions: ['A', 'VE', 'BB']
        }
    },
    {
        path: '/departments',
        name: 'Departments',
        component: () => import('../views/Departments.vue'),
        meta: {
            title: 'Betriebsstätten',
            permissions: ['A', 'VE']
        }
    },
    {
        path: '/checkoff',
        name: 'Checkoff',
        component: () => import('../views/Checkoff.vue'),
        meta: {
            title: 'BlCheckoff',
            permissions: ['A', 'PO', 'BA', 'BB', 'BD', 'VE', 'PR']
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
        meta: {
            title: 'Settings',
            permissions: ['A', 'VE']
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            'title': 'Login',
        }
    },
    {
        path: '/reset',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
            'title': 'Reset password',
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
