<template>
    <v-dialog
            v-model="dialog"
            max-width="800"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                Role
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-overlay :value="loading"></v-overlay>
                <validation-observer
                        ref="observer"
                >
                    <v-row v-if="!loading">
                        <v-col cols="12" sm="12">
                            <zw-input name="name"
                                      rules="required"
                                      label="Name"
                                      v-model="form.name"
                                      :readonly="readonly"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <zw-input name="code"
                                      label="Code"
                                      v-model="form.code"
                                      :readonly="readonly"
                                      :disabled="true"
                            ></zw-input>
                        </v-col>
                    </v-row>
                </validation-observer>

                <div v-if="!readonly" class="d-flex d-sm-none">
                    <v-bottom-navigation fixed background-color="success">
                        <v-btn @click="save()" icon class="white--text link">
                            <v-icon>check</v-icon>
                        </v-btn>
                    </v-bottom-navigation>
                </div>

            </v-card-text>
            <v-card-actions class="pr-6" v-if="!readonly && $vuetify.breakpoint.smAndUp">
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        class="white--text"
                        @click="save()"
                >
                    <span v-if="form.id">Speichern</span>
                    <span v-else>HINZUFÜGEN</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {updateRole, fetchRole} from './../graphql/role'
    import gql from 'graphql-tag'

    export default {
        data: () => ({
            dialog: false,
            loading: true,
            readonly: false,
            form: {},
            defaultForm: {
                'name': '',
            }
        }),
        methods: {
            ...mapGetters(['getRole']),
            close() {
                this.dialog = false
                this.loading = true
            },
            open(id, readonly = false) {
                this.readonly = readonly
                this.form = {...this.defaultForm}
                this.dialog = true

                this.$apollo.query({
                    query: fetchRole,
                    variables: {
                        id: parseInt(id),
                    },
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    this.form = {...data.role}
                    this.loading = false
                })
            },
            save() {
                this.$apollo.mutate({
                    mutation: updateRole,
                    variables: {
                        id: this.form.id,
                        name: this.form.name,
                    },
                }).then((data) => {
                    this.close()
                })
            },
        }
    }
</script>