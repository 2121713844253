<template>
    <validation-provider
            v-slot="{ errors }"
            :name="name"
    >
        <v-dialog
                ref="dialog"
                v-model="modal"
                :fullscreen="$vuetify.breakpoint.xsOnly"
                max-width="300"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                        v-model="inputVal"
                        :label="label"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        :clearable="clearable"
                        v-bind="attrs"
                        v-on="readonly ? null : on"
                        :hide-details="hideDetails"
                ></v-text-field>
            </template>
            <v-date-picker
                    v-model="inputVal"
                    scrollable
                    :full-width="$vuetify.breakpoint.xsOnly"
                    :min="min"
                    :max="max"
                    locale="de"
            >
                <v-spacer></v-spacer>
                <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(inputVal)"
                >
                    OK
                </v-btn>
            </v-date-picker>
        </v-dialog>
    </validation-provider>
</template>

<script>
    export default {
        props: ['value', 'name', 'rules', 'label', 'readonly', 'hideDetails', 'min', 'max', 'clearable'],
        data: () => ({
            modal: false,
        }),
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
    }
</script>