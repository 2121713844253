<template>
    <v-text-field
            v-model="inputVal"
            :label="label"
            readonly
            dense outlined hide-details
            @click="openTimeModal()"
            :error-messages="errorMessages"
    ></v-text-field>
</template>

<script>
    export default {
        props: ['value', 'name', 'rules', 'label', 'readonly', 'max', 'min', 'errorMessages', 'allowedMinutes'],
        data: () => ({
            modal: false,
            internalVal: null
        }),
        methods: {
            changed(val) {
                this.inputVal = val
                this.$emit('change')
            },
            openTimeModal() {
                if (!this.readonly) {
                    this.$root.$children[0].$refs['time-modal'].open(this.inputVal, this.min, this.max, this.allowedMinutes, this.changed)
                }
            }
        },
        mounted: function () {
            this.internalVal = this.value
        },
        computed: {
            inputVal: {
                get() {
                    return this.internalVal;
                },
                set(val) {
                    this.internalVal = val
                    this.$emit('input', val);
                }
            }
        },
        watch: {
            value: function (value) {
                this.internalVal = value
            }
        }
    }
</script>